@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #334564DB;
  
}
input[type="color" i] {
  -webkit-appearance: none;
  appearance: none;
}
input[type="color" i]::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar {
  display: none;
}
.poppins{
  font-family: 'Poppins';
}
